export const PUBLIC_ROUTER = [
  '/',
  '/signup',
  '/signup/infor',
  '/signup/pageview',
  '/login-3rd-party',
  '/api/auth/callback',
  '/callback',
];

export const ERROR_ROUTER = [
  '/errors/500',
  '/errors/404',
  '/callback',
  '/privacy-policy',
  '/terms-conditions',
  '/privacy-policy-web',
  '/terms-conditions-web',
];
